//import logo from './logo.svg';
import './App.css';
import CssBaseline from "@mui/material/CssBaseline";
import Box from '@mui/material/Box';
import InputAdornment from '@mui/material/InputAdornment';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';

import Icon from '@mdi/react';
import { mdiWeb } from '@mdi/js';


import {useState} from 'react';




function App() {
  const [firstYear, updateFirstYear] = useState(0)
  const [secondYear, updateSecondYear] = useState(0)

  const navigateToExternalUrl = (url, shouldOpenNewTab = true) =>
  shouldOpenNewTab ? window.open(url, "_blank") : window.location.href = url;

  const onChangePrice = (event) => {
//    const re = /^[0-9\b]+$/;
//    if (event.target.value === '' || re.test(event.target.value)) {
//      this.setState({value: event.target.value})
//    }
    console.log(event.target.value)
    const purchasingPrice = event.target.value
    updateFirstYear((purchasingPrice*.7).toFixed(2))
    updateSecondYear((purchasingPrice*.35).toFixed(2))
  }
  
  const onClickYears3_4 = (event) => {
    navigateToExternalUrl("https://darty-assurance.greendid.com/")
  }

  return (
    <div className="App" >
      <CssBaseline /> {/* apply normalize.css */}
      <Box
      component=""
      sx={{
        width: "100%",
        justifyContent: "center",
        alignItems: "center",
        display: "flex",
        //border: "3px solid red",
      }}
      >
      
      <Box
      component="form"
      sx={{
        width: "100%",
        maxWidth: '420px',
        //border: "3px solid green",
        '& .MuiTextField-root': { m: 1, width: '310px' },
        '& #title': { m: 1, width: '310px' },
      }}
      noValidate
      autoComplete="off"
      >
        <Box
          component="img"
          sx={{
            width: "100%",
            maxWidth: { xs: 350, md: 250 },
          }}
          alt="logo"
          src="darty_assurant.png"
        />
          <Typography id="title" variant="h5" gutterBottom>
          Reprise Calculator
        </Typography>
        <TextField
            id="purchasing_price"
            label="Prix d'achat"
            onChange={onChangePrice}
            InputProps={{
              endAdornment: <InputAdornment position="end">€</InputAdornment>,
              inputMode: 'numeric',
              type: 'number',
            }}
        />
        <TextField
            id="first_year"
            label="Montant reprise année 1"
            InputProps={{
              readOnly: true,
              endAdornment: <InputAdornment position="end">€</InputAdornment>,
            }}
            value = {firstYear}
          />
          <TextField
            id="second_year"
            label="Montant reprise année 2"
            InputProps={{
              readOnly: true,
              endAdornment: <InputAdornment position="end">€</InputAdornment>,
            }}
            value = {secondYear}
        />
        <TextField
          id="years_3_4"
          label="Montant de ma reprise année 3&4"
          onClick={onClickYears3_4}
          InputProps={{
            readOnly: true,
            endAdornment: <InputAdornment position="end"><Icon path={mdiWeb} size={1} /></InputAdornment>,
          }}
          value = "Prix du marché"
      />
      </Box>
      
      </Box>
    </div>
  );
}

export default App;


/*
     
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p>
          Edit <code>src/App.js</code> and save to reload.
        </p>
        <a
          className="App-link"
          href="https://reactjs.org"
          target="_blank"
          rel="noopener noreferrer"
        >
          Learn React
        </a>
      </header>
*/